<template>
  <div>
    <v-observer class="form-rounded-inputs" ref="form" tag="form" @submit.prevent="fnApiOauthToken()" v-if="!loading.oauth" >
      <h5 v-text="oauth.name"> </h5>
      <div class="row">
        <div class="col-12">
          <div class="form-group" v-text=" $t('oauth.message') ">
          </div>
        </div>

        <div class="col-auto ml-auto">
          <router-link to="/dashboard" class="btn mr-2" type="button" v-text="$t('general.button.decline')">

          </router-link> 
          <button class="btn btn-success" v-text="$t('general.button.authorize')">
          </button>
        </div>
      </div>
    </v-observer>
    <no-data v-else isLoading>

    </no-data>
  </div>
</template>

<script>
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex';
  export default {
    name: "Authorization",
    data() {
      return {
      }
    },
    computed: {
      ...mapState("session", ["oauth",'loading']),
    },
    methods: {
      ...mapMutations("session", ["setOauth"]),
      ...mapActions("session", ["fnApiOauthToken", "fnApiOauth"]),
    },
    mounted() {
      this.setOauth(this.$route.query);
    }
  }
</script>
